import mediaRepository from './mediaRepository';
import settingsRepository from './settingsRepository';

const repositories = {
  settings: settingsRepository,
  media: mediaRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
