import Repository from './Repository';

const resource = '/media';

export default {
  /**
   * Upload media
   * @param {Object} formData
   * @returns {Promise}
   */
  uploadMedia(formData) {
    return Repository.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  /**
   * Delete media
   *
   * @property {string} id - media id
   * @returns {Promise}
   */
  deleteMedia(id) {
    return Repository.delete(`${resource}/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

};
