import { UnleashClient } from 'unleash-proxy-client';

export default {
  install(Vue) {
    /**
     * Unleaseh plugin
     *
     * Create a global $unleash instance property
     */
    const unleash = new UnleashClient({
      url: process.env.VUE_APP_UNLEASH_URL,
      clientKey: process.env.VUE_APP_UNLEASH_TOKEN,
      refreshInterval: 30,
      appName: 'default',
    });

    Vue.prototype.$unleash = unleash;

    unleash.start();
  },
};
