import Repository from './Repository';

const resource = '/settings';

export default {

  /**
   * Get Microsite data
   * @param {object} params
   *
   * @property {number, string} page - filter sites by page number
   * @property {number, string} limit - how many sites data to show
   *
   * @returns {Promise}
   */
  getSites(params = {}) {
    return Repository.get(`${resource}`, { params });
  },
  postSite(body) {
    return Repository.post(`${resource}`, body);
  },
  checkDomain(params = {}) {
    return Repository.get(`${resource}/check-subdomain`, { params });
  },
  /**
   * Generate J-Site Redirect URL
   * @param {object} body
   *
   * @property {string} access_token - J-Site access token
   * @property {string} id - Site ID
   * @returns {Promise}
   */
  redirectUrl(body) {
    return Repository.post(`${resource}/redirect`, body);
  },
};
