import { getCookies, setCookies, removeCookie } from '@/common/helpers/cookies';
import { RepositoryFactory as MicrositeRepositoryFactory } from '@/repositories/microsite/RepositoryFactory';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const authRepository = RepositoryFactory.get('auth');
const settingsRepository = MicrositeRepositoryFactory.get('settings');

export default {
  namespaced: true,
  state: {
    listMicrosite: [],
  },
  getters: {
    hasMicrosite(state) {
      return state.listMicrosite.length > 0;
    },
  },
  mutations: {
    SET_LIST_MICROSITE(state, payload) {
      state.listMicrosite = payload;
    },
  },
  actions: {
    resetListMicrosite({ commit }) {
      commit('SET_LIST_MICROSITE', []);
    },
    async getListMicrosite({ commit, dispatch }) {
      try {
        const response = await settingsRepository.getSites();
        const { data } = response.data;

        if (data.length > 0) {
          commit('SET_LIST_MICROSITE', data);
          return;
        }

        dispatch('resetListMicrosite');
      } catch (error) {
        dispatch('resetListMicrosite');
      }
    },
    async generateJSiteToken() {
      try {
        const token = getCookies('j-site-token');

        if (token) return;

        const response = await authRepository.generateJSiteToken();
        const { access_token: jSiteToken } = response.data;

        if (jSiteToken) {
          setCookies({
            'j-site-token': jSiteToken,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async refreshJSiteToken() {
      try {
        removeCookie('j-site-token');

        const response = await authRepository.generateJSiteToken();
        const { access_token: jSiteToken } = response.data;

        if (jSiteToken) {
          setCookies({
            'j-site-token': jSiteToken,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    generateJSiteUrl(_, siteId) {
      const requestBody = {
        token: getCookies('j-site-token'),
        id: siteId,
      };

      if (process.env.VUE_APP_J_SITE_REDIRECT_URL) {
        requestBody.to = process.env.VUE_APP_J_SITE_REDIRECT_URL;
      }

      return settingsRepository.redirectUrl(requestBody);
    },
    revokeJSiteToken() {
      removeCookie('j-site-token');
    },
  },
};
